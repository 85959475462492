import request from '@/utils/http'

// 项目列表
export function queryList(params) {
  return request({
    url: '/alarm/pc/list',
    method: 'post',
    data: params
  })
}
