const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 11,
      title: '设备序列号',
      field: 'devSerial',
      width: 80,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      keyId: 2,
      title: '告警类型',
      field: 'alarmType',
      width: 80,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'lpr',
        label:'车牌识别'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 19,
      title: '告警通道名称',
      field: 'channelName',
      width: 80,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      keyId: 16,
      title: '出场放行时间',
      field: 'alarmTime',
      width: 180,
      fieldType: null,
      searchType: 'datetimerange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },{
      keyId: 22,
      title: '车牌号码',
      field: 'licensePlate',
      width: 80,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    }

  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      "keyId": 1,
      "title": "消息类型",
      "field": "type",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 2,
      "title": "设备序列号",
      "field": "deviceId",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 3,
      "title": "设备通道号",
      "field": "channelNo",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 4,
      "title": "消息唯一ID",
      "field": "messageId",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 5,
      "title": "消息时间",
      "field": "messageTime",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
   /* {
      "keyId": 6,
      "title": "设备序列号",
      "field": "devSerial",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 7,
      "title": "设备通道号",
      "field": "channel",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 8,
      "title": "设备通道类型",
      "field": "channelType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },*/
    {
      "keyId": 9,
      "title": "告警类型",
      "field": "alarmType",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    /*{
      "keyId": 10,
      "title": "告警ID",
      "field": "alarmId",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 11,
      "title": "告警关联ID",
      "field": "relationId",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 12,
      "title": "告警位置信息",
      "field": "location",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 13,
      "title": "告警描述",
      "field": "describeText",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
*/
    {
      "keyId": 16,
      "title": "告警时间",
      "field": "alarmTime",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    /*{
      "keyId": 17,
      "title": "自定义协议类型",
      "field": "customType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },*/
    {
      "keyId": 18,
      "title": "服务端记录的请求时间",
      "field": "requestTime",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 19,
      "title": "告警通道名称",
      "field": "channelName",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    /*{
      "keyId": 20,
      "title": "设备加密密码",
      "field": "checksum",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 21,
      "title": "图片加密类型",
      "field": "crypt",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },*/
    {
      "keyId": 22,
      "title": "报警自定义信息",
      "field": "customInfo",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 22,
      "title": "车牌号码",
      "field": "licensePlate",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    }, {
      "keyId": 23,
      "title": "方向",
      "field": "direction",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },{
      "keyId": 24,
      "title": "车牌颜色",
      "field": "plateColor",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
   /* {
      "keyId": 23,
      "title": "设备端的智能识别信息",
      "field": "intelligentData",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },*/
    {
      "keyId": 25,
      "title": "告警图片",
      "field": "ossPicListJson",
      // "field": "pictureListJson",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "imageArr",
      "copy": true
    },
    {
      "keyId": 26,
      "title": "车斗照片",
      "field": "carBodyDeviceUrl",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "keyId": 27,
      "title": "车斗照片保存时间",
      "field": "carBodyTime",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 28,
      "title": "消息接收时间",
      "field": "created",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    }
    ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn,
  components: {
    dateRange: ElDatePicker // 局部注册组件
  },
}
import ElDatePicker from 'element-ui/lib/date-picker'; // 根据实际情况调整路径